export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'Home',
    action: 'read',
  },
  {
    title: 'Users',
    route: 'users-list',
    icon: 'UserCheckIcon',
    resource: 'Users',
    action: 'read',
  },
  {
    title: 'Jobs',
    route: 'jobs-list',
    icon: 'ClipboardIcon',
    resource: 'Jobs',
    action: 'read',
  },
  {
    title: 'Vehicles',
    route: 'vehicles-list',
    icon: 'TruckIcon',
    resource: 'Vehicles',
    action: 'read',
  },
  {
    title: 'Customers',
    route: 'customers-list',
    icon: 'UsersIcon',
    resource: 'Customers',
    action: 'read',
  },
  {
    title: 'Job Templates',
    route: 'job-templates-list',
    icon: 'ListIcon',
    resource: 'Job Templates',
    action: 'read',
  },
]
